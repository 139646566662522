@import 'mixins.scss';
@import 'variables-aa.scss';

// APP LEVEL STYLE DEFINITIONS
.breadcrumb {
    padding: 24px 0px 0px 0px !important;
    margin-bottom: 20px;
    list-style: none;
    background-color: white;
    border-radius: 4px;
    i[class*="icon-"]:before{
      padding-right: 20px !important;
    }
    >li+li:before {
      padding: 8px 25px 8px 25px;
      color: #ccc;
      content: ">";
      font-size: 15px;
    }
    > a { padding: 0 5px;}

    @media screen and (max-width: 600px) {
        >li+li:before {
            padding: 8px 10px 8px 10px;
        }
    }
}
input, select {
  font-family: $aa-font;
}


a{
  color: $aa-blue;
}

label{
  width: 100%;
}

.icon-rotate-90{
  @include rotate(90deg);
}

.list-style-none{
  list-style-type: none;
}

.errorText {
  color: $aa-dark-red;
}

// Forms Inputs
textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    -webkit-appearance: none;
    border-radius: 0;
    color: #36495a;
    display: inline-block;
    padding: 8px 9px;
    vertical-align: middle;
    width: 100%;
}

select {
    -webkit-appearance: menulist;
}

textarea,
select,
input {
    font-size: 14px;
    font-family: $aa-font;

    &.ng-invalid.ng-touched {
        border: 1px solid $aa-med-red;
    }
}

.ngb-tp-meridian {
	button,button:hover, button:focus {
		background-color: $aa-ultra-light-gray;
		color: $aa-blue;
	}
}

nz-time-picker.ng-touched.ng-invalid > .ant-time-picker-input, div.dynamicInputDiv.ng-touched.ng-invalid > .errorBox  {
    border: 1px solid $aa-med-red !important;
}

.mat-calendar {
    background-color: white;
    border-radius: 5px;
}

.button-save{
    background-color: $aa-blue;
    height: 27px;
    width: 85px;
    padding: 0;
    color: $aa-ultra-light-gray;
    border-color: $aa-blue;
}

.button-cancel{
    height: 27px;
    width: 85px;
    padding: 0;
}

button {
    // -webkit-appearance: none;
    color: black;
    background-color: $aa-ultra-light-gray;
    border: none;
}


.mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0 5px !important;
}

.icon-circle-check {
    color: $aa-green;
    padding-right: 5px;
    padding-left: 5px;
    display: inline-block;
}

ul.dropdown-menu {
    width: 100%;
    li a {
        padding-left: 10px;
    }
  }

.panel-body {
    padding: 0;
}
label {
    font-weight: normal;
    margin-bottom: 0;
  }

  label.question {
    font-size: 16px;
  }

  .form-control {
    border-radius: 0;
    width: 100%;
  }

  .form-control[disabled],.form-control[readonly],fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1
}

