// imports - external libraries
@import "bootstrap/scss/bootstrap";
@import "variables-aa.scss";
@import "libs/datatables";
@import "general.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
i,
h2 {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

@font-face {
    font-family: "AmericanSans";
    src: url(/assets/fonts/AmericanSans-Regular.woff) format("woff"),
        url(/assets/fonts/AmericanSans-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "AmericanSans";
    src: url(/assets/fonts/AmericanSans-RegularItalic.ttf) format("truetype");
    font-weight: normal;
    font-style: italic;
}
// We decided not to use the bold font for now
// @font-face {
//     font-family: "AmericanSans";
//     src: url(/assets/fonts/AmericanSans-Bold.woff) format("woff"),
//         url(/assets/fonts/AmericanSans-Bold.ttf) format("truetype");
//     font-weight: bold;
//     font-style: normal;
// }
@font-face {
    font-family: "AmericanSans";
    src: url(/assets/fonts/AmericanSans-BoldItalic.ttf) format("truetype");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: "AmericanSansLight";
    src: url(/assets/fonts/americansans-light.woff) format("woff"),
        url(/assets/fonts/AmericanSans-Light.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "AmericanSansLight";
    src: url(/assets/fonts/AmericanSans-LightItalic.woff) format("woff"),
        url(/assets/fonts/AmericanSans-LightItalic.ttf) format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "AmericanSansMedium";
    src: url(/assets/fonts/AmericanSans-Medium.woff) format("woff"),
        url(/assets/fonts/AmericanSans-Medium.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "AmericanSansMedium";
    src: url(/assets/fonts/AmericanSans-MediumItalic.woff) format("woff"),
        url(/assets/fonts/AmericanSans-MediumItalic.ttf) format("truetype");
    font-weight: normal;
    font-style: italic;
}

// imports - internal static libraries
@import "mixins";
@import "icons";

@import "overrides/typography";
@import "overrides/lists";
@import "overrides/grid.scss";
@import "sec/sec-styles.scss";
@import "@progress/kendo-theme-core/scss/functions/index.import.scss";

$kendo-colors: ();
$kendo-colors: k-map-merge(
    $kendo-colors,
    k-generate-color-variations("primary", $aa-blue-50, "default")
);

$kendo-font-family: $aa-font-sec-regular;

@import "@progress/kendo-theme-default/dist/all.scss";

kendo-switch {
    justify-content: flex-end;
}

.k-switch-label-on,
.k-switch-label-off {
    color: $aa-blue-gray-90;
    text-align: left;
    font-family: $aa-font-sec-medium;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
}

.k-switch-track.k-rounded-full {
    width: 65px;
    height: 27px;
    background-color: $aa-white;
    border: 1px solid $aa-blue-gray-90;
}

.k-switch-thumb.k-rounded-full {
    width: 20px !important;
    height: 20px !important;
    border-style: solid;
    border-radius: 9999px;
}

.k-switch-off .k-switch-thumb.k-rounded-full {
    background-color: $aa-blue-gray-90;
    border-color: $aa-blue-gray-90;
}

.k-switch-on .k-switch-thumb.k-rounded-full {
    background-color: $aa-blue-50;
    border-color: $aa-blue-50;
}
.k-switch-md.k-switch-off .k-switch-thumb-wrap {
    left: 9px;
}

.k-switch-md.k-switch-on .k-switch-thumb-wrap {
    left: 46px;
}

.k-switch-md .k-switch-label-on {
    left: 9px;
}

// Base Application Styles

// --------------------
// Base Body Styles
body,
.body {
    font-family: $aa-font, Arial, Helvetica, sans-serif;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
}

// --------------------
// full-width app
.container-full {
    margin: 0 auto;
}

.container-full .row {
    margin-left: 0;
    margin-right: 0;
}

.debug-info {
    color: #dd8888;
}

// --------------------
.loader-center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

$lessy: red;
.scssy {
    color: $lessy;
}
.modal-backdrop.fade.in {
    z-index: -1;
    background: #ffffff;
}

a {
    cursor: pointer;
}

.report-section {
    margin-top: 20px;
}

.divider hr,
hr.divider {
    overflow: visible;
}

.divider hr {
    border-top: 1px solid #d0dae0;
    padding-bottom: 10px;
    background: none;
    margin: 20px 0 10px;
}

.cdk-overlay-container {
    z-index: 9999 !important;
}

// Override Bootstrap caret
$enable-caret: false;

// Kendo grid styles - start
.k-grid {
    border-radius: 8px;
    overflow: hidden;
    padding: 0px;
    height: 464px;
    border: 2px solid $aa-gray-30;
    box-shadow: 0px 2px 4px 0px rgba(19, 19, 19, 0.25);
}

.k-column-title {
    color: $aa-black;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.k-grid .k-table-th,
.k-grid .k-table-td,
.k-grid .k-grid-header-wrap,
.k-grid-header {
    border: none;
}

.k-table-row > .k-table-th,
.k-grid-header {
    background: $aa-gray-30;
}

.icon-sec-sort-default {
    color: $aa-black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 4px;
    width: 24px;
    height: 24px;
    font-size: 16px;
}

.icon-sec-alert-modal-warning-outline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 4px;
    width: 24px;
    height: 24px;
    font-size: 16px;
}

.k-cell-inner > .k-link > .icon-sec-sort-default {
    color: $aa-black;
}

.k-grid .k-grid-container {
    font-family: $aa-font-sec-regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
// // Kendo grid styles - end
