.list-basic,.aa-ul-list-basic {
    margin: 6px 12px;
    padding: 0;
    position: relative;
}

.list-basic li,.aa-ul-list-basic li {
    position: relative;
    display: block;
}

.list-basic li:before,.aa-ul-list-basic li:before {
    color: #9da6ab;
    content: "\25A0";
    left: -12px;
    position: absolute;
    line-height: 17.6px
}

.list-basic li>ul,.aa-ul-list-basic li>ul {
    margin-left: 12px
}

.list-basic li>ul>li:before,.aa-ul-list-basic li>ul>li:before {
    content: "\25CF"
}

.list-basic li>ul>li>ul>li:before,.aa-ul-list-basic li>ul>li>ul>li:before {
    color: #36495a;
    content: "\25CB"
}

.list-basic li+li,.aa-ul-list-basic li+li {
    margin-top: 6px
}
