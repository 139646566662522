// This file is registered globally in scss/bootstrap.scss
// Colors and constants are now imported in variables-aa.scss
// To access SEC constants and colors, use: "@import 'variables-aa.scss';"
@import 'sec-styles/fonts';
@import 'sec-styles/constants';

// ********************* ICON FONTS **************************** //

[class^="icon-sec"],
[class*=" icon-sec"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'SecFontIcons' !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.i-xs {
    font-size: 16px;
  }
  &.i-sm {
    font-size: 24px;
  }
  &.i-md {
    font-size: 32px;
  }
  &.i-lg {
    font-size: 48px;
  }
  &.i-xl {
    font-size: 64px;
  }
}

kendo-label {
  font-family: $aa-font-sec-regular;
  color: #131313;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  font-weight: bold;
  gap: 8px;
}

.kendo-label {
  font-family: $aa-font-sec-regular;
  color: #131313;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  font-weight: bold;
  gap: 8px;
}

.star {
  width: 8px;
  height: 8px;
  color: $aa-red-70;
  padding-left: 0px 0.47px 0px 0.47px;
  gap: 0px;
  opacity: 0px;
  font-size: 8px;
}

.error {
  cursor: pointer;
  padding: 1px;
  color: $aa-red-70;
  font-size: 14px;
}

.notif {
  top: 66px;
  font-size: 20px;
}

.k-notification-error {
  font-size: 15px !important;
  background-color: $aa-red-10 !important;
  color: $aa-red-50 !important;
}

.k-notification-success {
  font-size: 15px !important;
  background-color: $aa-green-10 !important;
  color: $aa-green-70 !important;
}
