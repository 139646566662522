@import '_colors'; 

// Font family variables
$aa-font-sec-bold: "americansans-bold";
$aa-font-sec-bold-italic: "americansans-bold-italic";
$aa-font-sec-light: "americansans-light";
$aa-font-sec-light-italic: "americansans-light-italic";
$aa-font-sec-medium: "americansans-medium";
$aa-font-sec-medium-italic: "americansans-medium-italic";
$aa-font-sec-regular: "americansans-regular";
$aa-font-sec-regular-italic: "americansans-regular-italic";

// Header 
$header-icon-bg-color   : $aa-blue-gray-90;
$header-icon-color      : $aa-white;
$header-icon-font-size  : 1.5rem;

// Body
$body-font-size         : 16px;

// Side Nav
$menu-item-width        : 350px;
$menu-item-height       : 32px;
$menu-item-top          : 20px;
$menu-item-left         : 20px;
$menu-item-padding      : 8px 20px 8px 20px;
$menu-item-bg-color     : $aa-blue-gray-90;
$menu-item-font-size    : 18px;
    
//Hover
$menu-item-border-color : $aa-blue-30;
$menu-item-hover-color  : $aa-white;
$menu-item-hover-bg-color  : $aa-blue-90;
$menu-item-hover-border    : 4px;
$menu-dropdown-height   : 24px;
$menu-dropdown-width    : 24px;
$menu-dropdown-color    : $aa-white;
$menu-dropdown-icon-height  : 10px;
$menu-dropdown-icon-width   : 16px;

// Active Menu Item

// Footer
$footer-bg-color    : $aa-blue-90;
$footer-color       : $aa-white;

/* ------ Button -------------- */
 
// Primary
$button-bg-color-primary            : $aa-blue-50;
$button-color-primary               : $aa-white;
$button-hover-bg-color-primary      : $aa-blue-90;
$button-hover-color-primary         : $aa-white;
$button-focus-bg-color-primary      : $aa-blue-50;
$button-focus-border-color-primary  : $aa-blue-32;
$button-focus-color-primary         : $aa-white;
$button-disabled-bg-color-primary   : $aa-blue-20;
$button-active-bg-color-primary     : $aa-blue-30;

// Secondary
$button-bg-color-secondary            : $aa-gray-20;
$button-color-secondary               : $aa-black;
$button-hover-bg-color-secondary      : $aa-gray-40;
$button-hover-color-secondary         : $aa-black;
$button-focus-bg-color-secondary      : $aa-gray-20;
$button-focus-border-color-secondary  : $aa-blue-32;
$button-focus-color-secondary         : $aa-black;
$button-disabled-bg-color-secondary   : $aa-gray-20;
$button-disabled-color-secondary      : $aa-black;
$button-active-bg-color-secondary     : $aa-gray-30;

// Tertiary
$button-bg-color-tertiary            : $aa-white;
$button-color-tertiary               : $aa-blue-50;
$button-border-color-tertiary        : $aa-blue-50;
$button-hover-bg-color-tertiary      : $aa-blue-10;
$button-hover-color-tertiary         : $aa-blue-90;
$button-hover-border-color-tertiary  : $aa-blue-90;
$button-focus-bg-color-tertiary      : $aa-white;
$button-focus-color-tertiary         : $aa-blue-50;
$button-focus-border-color-tertiary  : $aa-blue-32;
$button-disabled-bg-color-tertiary   : $aa-white;
$button-disabled-color-tertiary      : $aa-blue-20;
$button-disabled-border-color-tertiary  : $aa-blue-20;
$button-active-bg-color-tertiary        : $aa-blue-20;
$button-active-color-tertiary           : $aa-blue-90;
$button-active-border-color-tertiary    : $aa-blue-32;

// Specialty1

$button-color-specialty1    : $aa-blue-gray-90; 

// Accordion

$accordion-header-bg-color      : $aa-gray-10;
$accordion-header-color         : $aa-blue-gray-90;      
