  
  
  @media (max-width: 768px) {
    .containter{
      max-width: 750px !important;
      width:100% !important;
    }

    .col-md-3 {
      width: 50%;
      float: left;
    }
  }

  @media (min-width:250px){
    .container{
      min-width: 300px;
      width:100% !important;
    }

    .col-md-3 {
      width: 33%;
      float: left;
    }
  }

  @media (min-width:768px){
    .container{
      max-width:1010px !important;
      min-width:400px !important;
      width:100% !important;
    }

    .col-md-3 {
      width: 33%;
      float: left;
    }
  }

  @media (min-width: 1200px) {
    .container{
    max-width: 1268px !important;
    width:100% !important;
    }

    .col-md-3 {
      width: 25%;
      float: left;
    }
  }

  @media (min-width: 1400px) {
    .container{
      max-width: 1368px !important;
      width:100% !important;
      }
    }
  
    @media (min-width: 1600px) {
      .container{
      max-width: 1568px !important;
      width:100% !important;
      }
    }

  
