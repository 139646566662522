@import "constants";

@font-face {
    font-family: $aa-font-sec-bold;
    src: url("../sec-assets/fonts/americansans-bold.woff");
}

@font-face {
    font-family: $aa-font-sec-bold-italic;
    src: url("../sec-assets/fonts/americansans-bold_italic.woff");
}

@font-face {
    font-family: $aa-font-sec-light;
    src: url("../sec-assets/fonts/americansans-light.woff");
}

@font-face {
    font-family: $aa-font-sec-light-italic;
    src: url("../sec-assets/fonts/americansans-light_italic.woff");
}

@font-face {
    font-family: $aa-font-sec-medium;
    src: url("../sec-assets/fonts/americansans-medium.woff");
}

@font-face {
    font-family: $aa-font-sec-medium-italic;
    src: url("../sec-assets/fonts/americansans-medium_italic.woff");
}

@font-face {
    font-family: $aa-font-sec-regular;
    src: url("../sec-assets/fonts/americansans-regular.woff");
}

@font-face {
    font-family: $aa-font-sec-regular-italic;
    src: url("../sec-assets/fonts/americansans-regularitalic.woff");
}

@font-face {
    font-family: "SecFontIcons";
    src: url("../sec-assets/fonts/SecFontIcons.eot?t2mi3u");
    src: url("../sec-assets/fonts/SecFontIcons.eot?t2mi3u#iefix")
            format("embedded-opentype"),
        url("../sec-assets/fonts/SecFontIcons.ttf?t2mi3u") format("truetype"),
        url("../sec-assets/fonts/SecFontIcons.woff?t2mi3u") format("woff"),
        url("../sec-assets/fonts/SecFontIcons.svg?t2mi3u#SecFontIcons")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

//#region ********************* ICON FONTS **************************** //

.icon-sec-sort-default::before {
    content: "\e9d8";
}
.icon-sec-circle-empty:before {
    content: "\e900";
}
.icon-sec-circle-fill:before {
    content: "\e901";
}
.icon-sec-circle-half:before {
    content: "\e902";
}
.icon-sec-file-excel-fill:before {
    content: "\e903";
}
.icon-sec-file-excel-outline:before {
    content: "\e904";
}
.icon-sec-file-pdf-fill:before {
    content: "\e905";
}
.icon-sec-file-pdf-outline:before {
    content: "\e906";
}
.icon-sec-gear:before {
    content: "\e907";
}
.icon-sec-gear-1-fill:before {
    content: "\e908";
}
.icon-sec-gear-1-outline:before {
    content: "\e909";
}
.icon-sec-pencil:before {
    content: "\e90a";
}
.icon-sec-pencil-square:before {
    content: "\e90b";
}
.icon-sec-emergency-services-fill:before {
    content: "\e90c";
}
.icon-sec-emergency-services-outline:before {
    content: "\e90d";
}
.icon-sec-eye-fill-slash:before {
    content: "\e90e";
}
.icon-sec-eye-fill-visible:before {
    content: "\e90f";
}
.icon-sec-eye-outline-slash:before {
    content: "\e910";
}
.icon-sec-eye-outline-visible:before {
    content: "\e911";
}
.icon-sec-funnel-fill:before {
    content: "\e912";
}
.icon-sec-funnel-outline:before {
    content: "\e913";
}
.icon-sec-hospital-fill:before {
    content: "\e914";
}
.icon-sec-hospital-outine:before {
    content: "\e915";
}
.icon-sec-lightbulb-fill:before {
    content: "\e916";
}
.icon-sec-lightbulb-outline:before {
    content: "\e917";
}
.icon-sec-menu-app-nav:before {
    content: "\e918";
}
.icon-sec-menu-hamburger:before {
    content: "\e919";
}
.icon-sec-person-circle-fill .path1:before {
    content: "\e91a";
    color: rgb(19, 19, 19);
}
.icon-sec-person-circle-fill .path2:before {
    content: "\e91b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-person-circle-outline .path1:before {
    content: "\e91c";
    color: rgb(19, 19, 19);
}
.icon-sec-person-circle-outline .path2:before {
    content: "\e91d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-person-fill:before {
    content: "\e91e";
}
.icon-sec-person-outline:before {
    content: "\e91f";
}
.icon-sec-trash-fill:before {
    content: "\e920";
}
.icon-sec-trash-outline:before {
    content: "\e921";
}
.icon-sec-aircraft-mtx-fill .path1:before {
    content: "\e922";
    color: rgb(19, 19, 19);
}
.icon-sec-aircraft-mtx-fill .path2:before {
    content: "\e923";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-aircraft-mtx-fill .path3:before {
    content: "\e924";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-aircraft-mtx-outline:before {
    content: "\e925";
}
.icon-sec-air-emissions-fill:before {
    content: "\e926";
}
.icon-sec-air-emissions-outline .path1:before {
    content: "\e927";
    color: rgb(19, 19, 19);
}
.icon-sec-air-emissions-outline .path2:before {
    content: "\e928";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-air-emissions-outline .path3:before {
    content: "\e929";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-cargo-fill .path1:before {
    content: "\e92a";
    color: rgb(19, 19, 19);
}
.icon-sec-cargo-fill .path2:before {
    content: "\e92b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-cargo-fill .path3:before {
    content: "\e92c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-cargo-outline:before {
    content: "\e92d";
}
.icon-sec-customer-care-fill .path1:before {
    content: "\e92e";
    color: rgb(19, 19, 19);
}
.icon-sec-customer-care-fill .path2:before {
    content: "\e92f";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-customer-care-fill .path3:before {
    content: "\e930";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-customer-care-fill .path4:before {
    content: "\e931";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-customer-care-fill .path5:before {
    content: "\e932";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-customer-care-fill .path6:before {
    content: "\e933";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-customer-care-outline:before {
    content: "\e934";
}
.icon-sec-facilities-mtx-fill .path1:before {
    content: "\e935";
    color: rgb(19, 19, 19);
}
.icon-sec-facilities-mtx-fill .path2:before {
    content: "\e936";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-facilities-mtx-fill .path3:before {
    content: "\e937";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-facilities-mtx-outline:before {
    content: "\e938";
}
.icon-sec-gse-fill .path1:before {
    content: "\e939";
    color: rgb(19, 19, 19);
}
.icon-sec-gse-fill .path2:before {
    content: "\e93a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-gse-fill .path3:before {
    content: "\e93b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-gse-fill .path4:before {
    content: "\e93c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-gse-fill .path5:before {
    content: "\e93d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-gse-fill .path6:before {
    content: "\e93e";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-gse-outline:before {
    content: "\e93f";
}
.icon-sec-industrial-ww-fill .path1:before {
    content: "\e940";
    color: rgb(19, 19, 19);
}
.icon-sec-industrial-ww-fill .path2:before {
    content: "\e941";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-industrial-ww-fill .path3:before {
    content: "\e942";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-industrial-ww-fill .path4:before {
    content: "\e943";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-industrial-ww-fill .path5:before {
    content: "\e944";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-industrial-ww-fill .path6:before {
    content: "\e945";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-industrial-ww-outline:before {
    content: "\e946";
}
.icon-sec-potable-water-fill .path1:before {
    content: "\e947";
    color: rgb(19, 19, 19);
}
.icon-sec-potable-water-fill .path2:before {
    content: "\e948";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-potable-water-fill .path3:before {
    content: "\e949";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-potable-water-fill .path4:before {
    content: "\e94a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-potable-water-outline:before {
    content: "\e94b";
}
.icon-sec-ramp-fill .path1:before {
    content: "\e94c";
    color: rgb(19, 19, 19);
}
.icon-sec-ramp-fill .path2:before {
    content: "\e94d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-ramp-fill .path3:before {
    content: "\e94e";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-ramp-fill .path4:before {
    content: "\e94f";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-ramp-fill .path5:before {
    content: "\e950";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-ramp-fill .path6:before {
    content: "\e951";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-ramp-fill .path7:before {
    content: "\e952";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-ramp-fill .path8:before {
    content: "\e953";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-ramp-fill .path9:before {
    content: "\e954";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-ramp-fill .path10:before {
    content: "\e955";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-ramp-outline:before {
    content: "\e956";
}
.icon-sec-remediation-fill .path1:before {
    content: "\e957";
    color: rgb(19, 19, 19);
}
.icon-sec-remediation-fill .path2:before {
    content: "\e958";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path3:before {
    content: "\e959";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path4:before {
    content: "\e95a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path5:before {
    content: "\e95b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path6:before {
    content: "\e95c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path7:before {
    content: "\e95d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path8:before {
    content: "\e95e";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path9:before {
    content: "\e95f";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path10:before {
    content: "\e960";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path11:before {
    content: "\e961";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-fill .path12:before {
    content: "\e962";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-remediation-outline:before {
    content: "\e963";
}
.icon-sec-spcc-fill .path1:before {
    content: "\e964";
    color: rgb(19, 19, 19);
}
.icon-sec-spcc-fill .path2:before {
    content: "\e965";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-spcc-fill .path3:before {
    content: "\e966";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-spcc-outline:before {
    content: "\e967";
}
.icon-sec-spills-fill .path1:before {
    content: "\e968";
    color: rgb(19, 19, 19);
}
.icon-sec-spills-fill .path2:before {
    content: "\e969";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-spills-fill .path3:before {
    content: "\e96a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-spills-fill .path4:before {
    content: "\e96b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-spills-outline:before {
    content: "\e96c";
}
.icon-sec-stores-fill:before {
    content: "\e96d";
}
.icon-sec-stores-outline:before {
    content: "\e96e";
}
.icon-sec-storm-water-fill .path1:before {
    content: "\e96f";
    color: rgb(19, 19, 19);
}
.icon-sec-storm-water-fill .path2:before {
    content: "\e970";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path3:before {
    content: "\e971";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path4:before {
    content: "\e972";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path5:before {
    content: "\e973";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path6:before {
    content: "\e974";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path7:before {
    content: "\e975";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path8:before {
    content: "\e976";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path9:before {
    content: "\e977";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path10:before {
    content: "\e978";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path11:before {
    content: "\e979";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path12:before {
    content: "\e97a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-fill .path13:before {
    content: "\e97b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-storm-water-outline:before {
    content: "\e97c";
}
.icon-sec-tanks-fill .path1:before {
    content: "\e97d";
    color: rgb(19, 19, 19);
}
.icon-sec-tanks-fill .path2:before {
    content: "\e97e";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-tanks-fill .path3:before {
    content: "\e97f";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-tanks-fill .path4:before {
    content: "\e980";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-tanks-outline:before {
    content: "\e981";
}
.icon-sec-waste-fill .path1:before {
    content: "\e982";
    color: rgb(19, 19, 19);
}
.icon-sec-waste-fill .path2:before {
    content: "\e983";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-waste-fill .path3:before {
    content: "\e984";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-waste-fill .path4:before {
    content: "\e985";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-waste-fill .path5:before {
    content: "\e986";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-waste-fill .path6:before {
    content: "\e987";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-waste-fill .path7:before {
    content: "\e988";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-sec-waste-outline:before {
    content: "\e989";
}
.icon-sec-bell:before {
    content: "\e98a";
}
.icon-sec-calendar-blank:before {
    content: "\e98b";
}
.icon-sec-calendar-date:before {
    content: "\e98c";
}
.icon-sec-envelope-fill:before {
    content: "\e98d";
}
.icon-sec-envelope-fill-slash:before {
    content: "\e98e";
}
.icon-sec-envelope-outline:before {
    content: "\e98f";
}
.icon-sec-envelope-outline-slash:before {
    content: "\e990";
}
.icon-sec-floppy-fill:before {
    content: "\e991";
}
.icon-sec-floppy-outline:before {
    content: "\e992";
}
.icon-sec-printer-fill:before {
    content: "\e993";
}
.icon-sec-printer-outline:before {
    content: "\e994";
}
.icon-sec-search:before {
    content: "\e995";
}
.icon-sec-telephone-fill:before {
    content: "\e996";
}
.icon-sec-telephone-outline:before {
    content: "\e997";
}
.icon-sec-three-dots-horizontal:before {
    content: "\e998";
}
.icon-sec-three-dots-vertical:before {
    content: "\e999";
}
.icon-sec-chevron-bar-down:before {
    content: "\e99a";
}
.icon-sec-chevron-bar-left:before {
    content: "\e99b";
}
.icon-sec-chevron-bar-right:before {
    content: "\e99c";
}
.icon-sec-chevron-bar-up:before {
    content: "\e99d";
}
.icon-sec-chevron-compact-down:before {
    content: "\e99e";
}
.icon-sec-chevron-compact-left:before {
    content: "\e99f";
}
.icon-sec-chevron-compact-right:before {
    content: "\e9a0";
}
.icon-sec-chevron-compact-up:before {
    content: "\e9a1";
}
.icon-sec-chevron-double-down:before {
    content: "\e9a2";
}
.icon-sec-chevron-double-left:before {
    content: "\e9a3";
}
.icon-sec-chevron-double-right:before {
    content: "\e9a4";
}
.icon-sec-chevron-double-up:before {
    content: "\e9a5";
}
.icon-sec-chevron-down:before {
    content: "\e9a6";
}
.icon-sec-chevron-expand-contract-contract:before {
    content: "\e9a7";
}
.icon-sec-chevron-expand-contract-contract-bar:before {
    content: "\e9a8";
}
.icon-sec-chevron-expand-contract-expand:before {
    content: "\e9a9";
}
.icon-sec-chevron-expand-contract-expand-bar:before {
    content: "\e9aa";
}
.icon-sec-chevron-left:before {
    content: "\e9ab";
}
.icon-sec-chevron-right:before {
    content: "\e9ac";
}
.icon-sec-chevron-up:before {
    content: "\e9ad";
}
.icon-sec-caret-down-fill:before {
    content: "\e9ae";
}
.icon-sec-caret-down-outline:before {
    content: "\e9af";
}
.icon-sec-caret-left-fill:before {
    content: "\e9b0";
}
.icon-sec-caret-left-outline:before {
    content: "\e9b1";
}
.icon-sec-caret-right-fill:before {
    content: "\e9b2";
}
.icon-sec-caret-right-outline:before {
    content: "\e9b3";
}
.icon-sec-caret-square-down-fill:before {
    content: "\e9b4";
}
.icon-sec-caret-square-down-outline:before {
    content: "\e9b5";
}
.icon-sec-caret-square-left-fill:before {
    content: "\e9b6";
}
.icon-sec-caret-square-left-outline:before {
    content: "\e9b7";
}
.icon-sec-caret-square-right-fill:before {
    content: "\e9b8";
}
.icon-sec-caret-square-right-outline:before {
    content: "\e9b9";
}
.icon-sec-caret-square-up-fill:before {
    content: "\e9ba";
}
.icon-sec-caret-square-up-outline:before {
    content: "\e9bb";
}
.icon-sec-caret-up-fill:before {
    content: "\e9bc";
}
.icon-sec-caret-up-outline:before {
    content: "\e9bd";
}
.icon-sec-box-arrow-in-down:before {
    content: "\e9be";
}
.icon-sec-box-arrow-in-down-left:before {
    content: "\e9bf";
}
.icon-sec-box-arrow-in-down-right:before {
    content: "\e9c0";
}
.icon-sec-box-arrow-in-left:before {
    content: "\e9c1";
}
.icon-sec-box-arrow-in-right:before {
    content: "\e9c2";
}
.icon-sec-box-arrow-in-up:before {
    content: "\e9c3";
}
.icon-sec-box-arrow-in-up-left:before {
    content: "\e9c4";
}
.icon-sec-box-arrow-in-up-right:before {
    content: "\e9c5";
}
.icon-sec-box-arrow-out-down:before {
    content: "\e9c6";
}
.icon-sec-box-arrow-out-down-left:before {
    content: "\e9c7";
}
.icon-sec-box-arrow-out-down-right:before {
    content: "\e9c8";
}
.icon-sec-box-arrow-out-left:before {
    content: "\e9c9";
}
.icon-sec-box-arrow-out-right:before {
    content: "\e9ca";
}
.icon-sec-box-arrow-out-up:before {
    content: "\e9cb";
}
.icon-sec-box-arrow-out-up-left:before {
    content: "\e9cc";
}
.icon-sec-box-arrow-out-up-right:before {
    content: "\e9cd";
}
.icon-sec-arrow-down:before {
    content: "\e9ce";
}
.icon-sec-arrow-down-left:before {
    content: "\e9cf";
}
.icon-sec-arrow-down-right:before {
    content: "\e9d0";
}
.icon-sec-arrow-left:before {
    content: "\e9d1";
}
.icon-sec-arrow-right:before {
    content: "\e9d2";
}
.icon-sec-arrow-sort-down:before {
    content: "\e9d3";
}
.icon-sec-arrow-sort-left:before {
    content: "\e9d4";
}
.icon-sec-arrow-sort-left-right:before {
    content: "\e9d5";
}
.icon-sec-arrow-sort-right:before {
    content: "\e9d6";
}
.icon-sec-arrow-sort-up:before {
    content: "\e9d7";
}
.icon-sec-arrow-sort-up-down:before {
    content: "\e9d8";
}
.icon-sec-arrow-up:before {
    content: "\e9d9";
}
.icon-sec-arrow-up-left:before {
    content: "\e9da";
}
.icon-sec-arrow-up-right:before {
    content: "\e9db";
}
.icon-sec-check-circle-fill:before {
    content: "\e9dc";
}
.icon-sec-check-circle-outline:before {
    content: "\e9dd";
}
.icon-sec-check-outline-1:before {
    content: "\e9de";
}
.icon-sec-check-outline-2:before {
    content: "\e9df";
}
.icon-sec-check-square-fill:before {
    content: "\e9e0";
}
.icon-sec-check-square-outline:before {
    content: "\e9e1";
}
.icon-sec-dash-circle-fill:before {
    content: "\e9e2";
}
.icon-sec-dash-circle-outline:before {
    content: "\e9e3";
}
.icon-sec-dash-outline:before {
    content: "\e9e4";
}
.icon-sec-dash-square-fill:before {
    content: "\e9e5";
}
.icon-sec-dash-square-outline:before {
    content: "\e9e6";
}
.icon-sec-diamond-fill:before {
    content: "\e9e7";
}
.icon-sec-diamond-outline:before {
    content: "\e9e8";
}
.icon-sec-exclamation-circle-fill:before {
    content: "\e9e9";
}
.icon-sec-exclamation-circle-outline:before {
    content: "\e9ea";
}
.icon-sec-exclamation-diamond-fill:before {
    content: "\e9eb";
}
.icon-sec-exclamation-diamond-outline:before {
    content: "\e9ec";
}
.icon-sec-exclamation-outline:before {
    content: "\e9ed";
}
.icon-sec-exclamation-square-fill:before {
    content: "\e9ee";
}
.icon-sec-exclamation-square-outline:before {
    content: "\e9ef";
}
.icon-sec-exclamation-triangle-fill:before {
    content: "\e9f0";
}
.icon-sec-exclamation-triangle-outline:before {
    content: "\e9f1";
}
.icon-sec-info-circle-fill:before {
    content: "\e9f2";
    color: $aa-blue-50;
}
.icon-sec-info-circle-outline:before {
    content: "\e9f3";
}
.icon-sec-info-outline:before {
    content: "\e9f4";
}
.icon-sec-info-square-fill:before {
    content: "\e9f5";
}
.icon-sec-info-square-outline:before {
    content: "\e9f6";
}
.icon-sec-plus-circle-fill:before {
    content: "\e9f7";
}
.icon-sec-plus-circle-outline:before {
    content: "\e9f8";
}
.icon-sec-plus-outline:before {
    content: "\e9f9";
}
.icon-sec-plus-square-fill:before {
    content: "\e9fa";
}
.icon-sec-plus-square-outline:before {
    content: "\e9fb";
}
.icon-sec-question-circle-fill:before {
    content: "\e9fc";
}
.icon-sec-question-circle-outline:before {
    content: "\e9fd";
}
.icon-sec-question-diamond-fill:before {
    content: "\e9fe";
}
.icon-sec-question-diamond-outline:before {
    content: "\e9ff";
}
.icon-sec-question-outline:before {
    content: "\ea00";
}
.icon-sec-question-square-fill:before {
    content: "\ea01";
}
.icon-sec-question-square-outline:before {
    content: "\ea02";
}
.icon-sec-slash-circle-fill:before {
    content: "\ea03";
}
.icon-sec-slash-circle-outline:before {
    content: "\ea04";
}
.icon-sec-slash-outline:before {
    content: "\ea05";
}
.icon-sec-slash-square-fill:before {
    content: "\ea06";
}
.icon-sec-slash-square-outline:before {
    content: "\ea07";
}
.icon-sec-x-circle-fill:before {
    content: "\ea08";
}
.icon-sec-x-circle-outline:before {
    content: "\ea09";
}
.icon-sec-x-outline:before {
    content: "\ea0a";
}
.icon-sec-x-square-fill:before {
    content: "\ea0b";
}
.icon-sec-x-square-outline:before {
    content: "\ea0c";
}
.icon-sec-alert-input-error-outline:before {
    content: "\ea0d";
    color: #c30019;
}
.icon-sec-alert-modal-error-fill .path1:before {
    content: "\ea0e";
    color: rgb(255, 255, 255);
}
.icon-sec-alert-modal-error-fill .path2:before {
    content: "\ea0f";
    margin-left: -1em;
    color: rgb(195, 0, 25);
}
.icon-sec-alert-modal-error-outline:before {
    content: "\ea10";
    color: #c30019;
}
.icon-sec-alert-modal-info-fill .path1:before {
    content: "\ea11";
    color: rgb(255, 255, 255);
}
.icon-sec-alert-modal-info-fill .path2:before {
    content: "\ea12";
    margin-left: -1em;
    color: rgb(0, 120, 210);
}
.icon-sec-alert-modal-info-outline:before {
    content: "\ea13";
    color: #0078d2;
}
.icon-sec-alert-modal-success-fill .path1:before {
    content: "\ea14";
    color: rgb(255, 255, 255);
}
.icon-sec-alert-modal-success-fill .path2:before {
    content: "\ea15";
    margin-left: -1em;
    color: rgb(0, 185, 137);
}
.icon-sec-alert-modal-success-outline:before {
    content: "\ea16";
    color: #00b989;
}
.icon-sec-alert-modal-warning-fill .path1:before {
    content: "\ea17";
    color: rgb(255, 255, 255);
}
.icon-sec-alert-modal-warning-fill .path2:before {
    content: "\ea18";
    margin-left: -1em;
    color: rgb(255, 115, 24);
}
.icon-sec-alert-modal-warning-outline:before {
    content: "\ea19";
    color: #ff7318;
}

//#endregion
