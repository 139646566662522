//
// Custom Mixins
// --------------------------------------------------

//
// Load Fonts
// --------------------------------------------------
@mixin load-font($font-family, $src-url, $font-weight, $font-style){
	@font-face: {
		font-family: $font-family;
		src: url($src-url);
		font-weight: $font-weight;
		font-style: $font-style;
	}
}

@mixin gradient($startColor: #eee, $endColor: white){
	background-color: $startColor;
	background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
	background: -webkit-linear-gradient(top, $startColor, $endColor);
	background: -moz-linear-gradient(top, $startColor, $endColor);
	background: -ms-linear-gradient(top, $startColor, $endColor);
	background: -o-linear-gradient(top, $startColor, $endColor);
	background: linear-gradient(180deg, $startColor, $endColor);
}

@mixin animation($name, $duration: 300ms, $delay: 0, $ease: ease){
	-webkit-animation: $name $duration $delay $ease;
	-moz-animation:    $name $duration $delay $ease;
	-ms-animation:     $name $duration $delay $ease;
}

@mixin transition($transition){
	-webkit-transition: $transition;  
	-moz-transition:    $transition;
	-ms-transition:     $transition; 
	-o-transition:      $transition;  
}
@mixin transform($string){
	-webkit-transform: $string;
	-moz-transform: 	 $string;
	-ms-transform: 		 $string;
	-o-transform: 		 $string;
}
@mixin scale($factor){
	-webkit-transform: scale($factor);
	-moz-transform: 	 scale($factor);
	-ms-transform: 		 scale($factor);
	-o-transform: 		 scale($factor);
}
@mixin rotate($deg){
	-webkit-transform: rotate($deg);
	-moz-transform: 	 rotate($deg);
	-ms-transform: 		 rotate($deg);
	-o-transform: 		 rotate($deg);
}
@mixin skew($deg, $deg2){
	-webkit-transform:       skew($deg, $deg2);
	-moz-transform: 	 skew($deg, $deg2);
	-ms-transform: 		 skew($deg, $deg2);
	-o-transform: 		 skew($deg, $deg2);
}
@mixin translate($x, $y:0){
	-webkit-transform:       translate($x, $y);
	-moz-transform: 	 translate($x, $y);
	-ms-transform: 		 translate($x, $y);
	-o-transform: 		 translate($x, $y);
}
@mixin translate3d($x, $y: 0, $z: 0){
	-webkit-transform:       translate3d($x, $y, $z);
	-moz-transform: 	 translate3d($x, $y, $z);
	-ms-transform: 		 translate3d($x, $y, $z);
	-o-transform: 		 translate3d($x, $y, $z);
}
@mixin perspective($value: 1000){
	-webkit-perspective: 	$value;
	-moz-perspective: 	$value;
	-ms-perspective: 	$value;
	perspective: 		$value;
}
@mixin transform-origin($x:center, $y:center){
	-webkit-transform-origin: $x $y;
	-moz-transform-origin:    $x $y;
	-ms-transform-origin:     $x $y;
	-o-transform-origin:      $x $y;
}

@mixin opacity($opacity){
  $mfopacity: $opacity*100;
  $fopacity: "alpha(opacity=#{$mfopacity})";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$mfopacity})";
  filter: unquote("#{$fopacity}");
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
}