/*------------------------------------*\
    $ICONS
\*------------------------------------*/
/**
  * <i class="icon-name"></i>
  */
/**
  * Load in the american-icons font
  */
@font-face {
  font-family: "American Icons";
  src: url('/assets/fonts/american-icons-v4.eot');
  src: url('/assets/fonts/american-icons-v4.eot?#iefix') format('eot'), url('/assets/fonts/american-icons-v4.woff') format('woff'), url('/assets/fonts/american-icons-v4.ttf') format('truetype'), url('/assets/fonts/american-icons-v4.svg#american-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

h2 [class^="icon-"]:before, .beta [class^="icon-"]:before, .ui-dialog .ui-dialog-title [class^="icon-"]:before {
  font-size: 16px;
  font-size: 1.33333rem;
  vertical-align: middle;
}

h3 [class^="icon-"]:before, .gamma [class^="icon-"]:before, [class|="message"] .header [class^="icon-"]:before, .aa-summary:first-line [class^="icon-"]:before, .menu-blurb .menu-blurb-head [class^="icon-"]:before {
  font-size: 16px;
  font-size: 1.33333rem;
}

h4 [class^="icon-"]:before, .delta [class^="icon-"]:before, .aa-trip-dashboard-price-summary table [class^="icon-"]:before, .aa-trip-dashboard-total-price [class^="icon-"]:before,
.aa-trip-dashboard-total-price-award [class^="icon-"]:before, .aa-trip-dashboard-price-summary h3 [class^="icon-"]:before, table.aa-card th [class^="icon-"]:before,
table.aa-card thead td [class^="icon-"]:before {
  font-size: 16px;
  font-size: 1.33333rem;
}

h5 [class^="icon-"]:before, .epsilon [class^="icon-"]:before, .aa-tooltip .tooltip-title [class^="icon-"]:before, .aa-step-indicator [class^="icon-"]:before, .aa-summary [class^="icon-"]:before, .aa-card .aa-flight-dest [class^="icon-"]:before, .aa-card-flight h6 [class^="icon-"]:before, .menu-item-sub [class^="icon-"]:before, .box-hero-tailfin [class^="icon-"]:before {
  font-size: 12px;
  font-size: 1rem;
  vertical-align: baseline;
}

/**
  * Basic icon-class
  */
/* line 17, ../../../../../../src/main/sass/content/css/partials/_icons.scss */

/*  we're importing sec icons for new functionality in other parts of the application.
    therefore we need to exclude sec icons from here so that functionality between the two
    is not overwritten. */

i:not([class*="sec-"])[class*="icon-"] {
  display: inline-block;
}
/* line 20, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
i:not([class*="sec-"])[class*="icon-"]:before {
  font-family: 'American Icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}

.custom-date-time-picker i:before {
  font-size: 13px;
  font-size: 1.333rem;
  font-family: 'American Icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}
.custom-date-time-picker span:before {
  font-size: 13px;
  font-size: 1.71429rem;
  font-family: 'American Icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}

/**
  * Most-used sizes for convenience
  * <i class="icon-name icon-XX"></i>
  */
/* line 35, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-18:before {
  font-size: 18px;
  font-size: 1.5rem;
}

/* line 38, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-small:before {
  font-size: 11px;
  font-size: 0.91667rem;
}

/* line 41, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-default:before {
  font-size: 13px;
  font-size: 1.08333rem;
}

/* line 44, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-medium:before {
  font-size: 16px;
  font-size: 1.33333rem;
}

/* line 47, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-large:before {
  font-size: 22px;
  font-size: 1.83333rem;
}

/* line 50, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-xlarge:before {
  font-size: 54px;
  font-size: 4.5rem;
  line-height: 1;
}

/**
  * Individual icons
  */
/* line 58, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-people:before {
  content: "\e000";
}

/* line 61, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-attendant:before {
  content: "\e001";
}

/* line 64, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-stairs:before {
  content: "\e002";
}

/* line 67, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-escalator-b:before {
  content: "\e003";
}

/* line 70, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-escalator:before {
  content: "\e004";
}

/* line 73, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-hotel:before {
  content: "\e005";
}

/* line 77, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-handicap:before,
.icon-specialAssistance:before {
  content: "\e006";
}

/* line 80, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-seats:before {
  content: "\e007";
}

/* line 83, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-carseat:before {
  content: "\e008";
}

/* line 86, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-stroller:before {
  content: "\e009";
}

/* line 89, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-checkin:before {
  content: "\e00a";
}

/* line 92, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-book:before {
  content: "\e00b";
}

/* line 95, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-account:before {
  content: "\e00c";
}

/* line 98, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-join:before {
  content: "\e00d";
}

/* line 101, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-suitcase:before {
  content: "\e00e";
}

/* line 104, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-twobags:before {
  content: "\e00f";
}

/* line 107, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-threebags:before {
  content: "\e010";
}

/* line 110, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-checkbags:before {
  content: "\e011";
}

/* line 113, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-Trolley:before {
  content: "\e012";
}

/* line 116, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-baginfo:before {
  content: "\e013";
}

/* line 119, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-flight:before, .aa-product-icons li a:before,
.aa-product-icons li button.call-to-action:before,
.aa-product-icons li button#aa-buy-miles:before {
  content: "\e014";
}

/* line 122, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-car:before {
  content: "\e015";
}

/* line 125, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-taxi:before {
  content: "\e016";
}

/* line 128, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-vacation:before {
  content: "\e017";
}

/* line 131, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-activity:before {
  content: "\e018";
}

/* line 134, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-entertainment:before {
  content: "\e019";
}

/* line 137, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-cruise:before {
  content: "\e01a";
}

/* line 140, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-food:before {
  content: "\e01b";
}

/* line 143, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-foodpurchase:before {
  content: "\e01c";
}

/* line 146, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-snack:before {
  content: "\e01d";
}

/* line 149, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-drink:before {
  content: "\e01e";
}

/* line 152, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-coffee:before {
  content: "\e01f";
}

/* line 155, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-nosmoking:before {
  content: "\e020";
}

/* line 158, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-payment:before {
  content: "\e021";
}

/* line 161, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-cash:before {
  content: "\e022";
}

/* line 164, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-scanswipe:before {
  content: "\e023";
}

/* line 167, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-promotion:before {
  content: "\e024";
}

/* line 170, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-boardingpass:before {
  content: "\e025";
}

/* line 173, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-onedaypass:before {
  content: "\e026";
}

/* line 176, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-mobile:before {
  content: "\e027";
}

/* line 179, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-nomobile:before {
  content: "\e028";
}

/* line 182, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-game:before {
  content: "\e029";
}

/* line 185, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-contact:before {
  content: "\e02a";
}

/* line 188, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-print:before {
  content: "\e02b";
}

/* line 191, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-website:before {
  content: "\e02c";
}

/* line 194, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-email:before {
  content: "\e02d";
}

/* line 197, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-clock:before {
  content: "\e02e";
}

/* line 200, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-wifi:before {
  content: "\e02f";
}

/* line 203, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-location:before {
  content: "\e030";
}

/* line 206, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-maps:before {
  content: "\e031";
}

/* line 209, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-gps:before {
  content: "\e032";
}

/* line 212, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-home:before {
  content: "\e033";
}

/* line 215, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-notifications:before {
  content: "\e034";
}

/* line 218, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-feedback:before {
  content: "\e035";
}

/* line 221, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-settings:before {
  content: "\e036";
}

/* line 224, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-tools:before {
  content: "\e037";
}

/* line 227, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-login:before {
  content: "\e038";
}

/* line 230, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-search:before {
  content: "\e039";
}

/* line 233, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-calendar:before, .ui-datepicker-trigger:before {
  content: "\e03a";
}

/* line 236, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-help:before {
  content: "\e03b";
}

/* line 239, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-info:before, .message-info:before, .message-inline-info:before {
  content: "\e03c";
}

/* line 242, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-error:before, .is-error-icon:before, label.is-error:before,
.label.is-error:before, [class*="input-group"] .is-error.legend:before,
.is-error .label:before, .is-error [class*="input-group"] .legend:before,
[class*="input-group"] .is-error .legend:before, .message-error:before,
.message-inline-error:before {
  content: "\e03d";
}

/* line 246, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-warning:before, .icon-alert:before, .message-warning:before, .message-inline-warning:before {
  content: "\e03e";
}

/* line 249, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-newpage:before {
  content: "\e03f";
}

/* line 252, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-arrow-up:before {
  content: "\e040";
}

/* line 255, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-arrow-down:before {
  content: "\e041";
}

/* line 258, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-arrow-left:before {
  content: "\e042";
}

/* line 261, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-arrow-right:before {
  content: "\e043";
}

/* line 264, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-circle-up:before {
  content: "\e044";
}

/* line 267, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-circle-down-b:before {
  content: "\e045";
}

/* line 270, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-circle-down:before {
  content: "\e046";
}

/* line 273, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-circle-left:before {
  content: "\e047";
}

/* line 276, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-circle-right:before {
  content: "\e048";
}

/* line 279, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-check:before {
  content: "\e049";
}

/* line 282, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-cancel:before {
  content: "\e04a";
}

/* line 285, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-circle-check:before {
  content: "\e04b";
}

/* line 289, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-circle-cancel:before,
.icon-close:before {
  content: "\e04c";
}

/* line 292, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-more-13px:before {
  content: "\e04d";
}

/* line 295, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-collapse:before {
  content: "\e04e";
}

/* line 298, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-expand:before {
  content: "\e04f";
}

/* line 301, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-explosives:before {
  content: "\e050";
}

/* line 304, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-bullets:before {
  content: "\e051";
}

/* line 307, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-fireworks:before {
  content: "\e052";
}

/* line 310, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-gascylinder45:before {
  content: "\e053";
}

/* line 313, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-oxygentank:before {
  content: "\e054";
}

/* line 316, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-aerosol:before {
  content: "\e055";
}

/* line 319, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-gascylinder:before {
  content: "\e056";
}

/* line 322, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-radioactive:before {
  content: "\e057";
}

/* line 325, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-flammable:before {
  content: "\e058";
}

/* line 328, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-gascan:before {
  content: "\e059";
}

/* line 331, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-match:before {
  content: "\e05a";
}

/* line 334, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-nailpolishremover:before {
  content: "\e05b";
}

/* line 337, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-chainsawsmooth:before {
  content: "\e05c";
}

/* line 340, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-chainsaw:before {
  content: "\e05d";
}

/* line 343, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-lighterandfluid:before {
  content: "\e05e";
}

/* line 346, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-corrosive:before {
  content: "\e05f";
}

/* line 349, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-oxidizer:before {
  content: "\e060";
}

/* line 352, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-bleach:before {
  content: "\e061";
}

/* line 355, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-hydrogenperoxide:before {
  content: "\e062";
}

/* line 358, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-ajaxcomet:before {
  content: "\e063";
}

/* line 361, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-skullandbones:before {
  content: "\e064";
}

/* line 364, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-skullandbonesbottle:before {
  content: "\e065";
}

/* line 367, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-skullandbonesbox:before {
  content: "\e066";
}

/* line 370, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-carbattery:before {
  content: "\e067";
}

/* line 373, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-lightningbolt:before {
  content: "\e068";
}

/* line 376, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-lithiumion:before {
  content: "\e069";
}

/* line 379, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-drill:before {
  content: "\e06a";
}

/* line 382, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-edit:before {
  content: "\e06b";
}

/* line 385, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-list:before {
  content: "\e06c";
}

/* line 388, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-menu:before {
  content: "\e06d";
}

/* line 391, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-power-port:before {
  content: "\e06e";
}

/* line 394, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.icon-clothes-hanger:before {
  content: "\e06f";
}

.icon-delete:before {
  content: "\e070";
}

.icon-add:before {
  content: "\e071";
}

.icon-remove:before {
  content: "\e070";
}

.icon-preferences:before {
  content: "\e072";
}

.icon-like:before {
  content: "\e073";
}

.icon-doc:before {
  content: "\e074";
}

i.icon-cers-required:before{
    content:'\e075';
    font-size: 11px !important;
    color: $aa-dark-red;
}

i.icon-cers-required{
    vertical-align: middle !important;
    min-width: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
}
i.icon-cers-required-space{
    content:'';
    vertical-align: middle !important;
    min-width: 15px;
}
.icon-delete{
    color: red;
}

/**
  * Flag icons for the language selector
  * @TODO change this into a generic icon- class?
  */
/* line 402, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-language-select-indicator {
  background-image: url(/content/images/chrome/rebrand/aa-icons-flags-sprite.png);
  background-repeat: no-repeat;
  height: 24px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 4px;
  padding: 0;
  width: 24px;
}

/* line 414, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-language-select-indicator,
.aa-cntry-US .aa-language-select-indicator {
  background-position: -150px 0px;
}

/* line 418, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-AR .aa-language-select-indicator {
  background-position: -210px -120px;
}

/* line 422, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-AU .aa-language-select-indicator {
  background-position: -210px -60px;
}

/* line 426, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-BZ .aa-language-select-indicator {
  background-position: -150px -180px;
}

/* line 430, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-BE .aa-language-select-indicator {
  background-position: -180px -180px;
}

/* line 434, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-BO .aa-language-select-indicator {
  background-position: -90px -180px;
}

/* line 438, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-BR .aa-language-select-indicator {
  background-position: -60px -180px;
}

/* line 442, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-CA .aa-language-select-indicator {
  background-position: -30px -180px;
}

/* line 446, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-CL .aa-language-select-indicator {
  background-position: -180px -150px;
}

/* line 450, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-CN .aa-language-select-indicator {
  background-position: -150px -150px;
}

/* line 454, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-CO .aa-language-select-indicator {
  background-position: -120px -150px;
}

/* line 458, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-CR .aa-language-select-indicator {
  background-position: -90px -150px;
}

/* line 462, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-DO .aa-language-select-indicator {
  background-position: 0px -150px;
}

/* line 466, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-EC .aa-language-select-indicator {
  background-position: -150px -120px;
}

/* line 470, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-SV .aa-language-select-indicator {
  background-position: -180px -120px;
}

/* line 474, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-FR .aa-language-select-indicator {
  background-position: -120px -120px;
}

/* line 478, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-DE .aa-language-select-indicator {
  background-position: -90px -120px;
}

/* line 482, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-GT .aa-language-select-indicator {
  background-position: -30px -120px;
}

/* line 486, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-HN .aa-language-select-indicator {
  background-position: -180px -90px;
}

/* line 490, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-IN .aa-language-select-indicator {
  background-position: -150px -90px;
}

/* line 494, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-IE .aa-language-select-indicator {
  background-position: -120px -90px;
}

/* line 498, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-IT .aa-language-select-indicator {
  background-position: -90px -90px;
}

/* line 502, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-JP .aa-language-select-indicator {
  background-position: 0px 0px;
}

/* line 506, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-KR .aa-language-select-indicator {
  background-position: -180px -30px;
}

/* line 510, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-MX .aa-language-select-indicator {
  background-position: -30px -90px;
}

/* line 514, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-NL .aa-language-select-indicator {
  background-position: 0px -90px;
}

/* line 518, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-NI .aa-language-select-indicator {
  background-position: -180px -60px;
}

/* line 522, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-PA .aa-language-select-indicator {
  background-position: -150px -60px;
}

/* line 526, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-PE .aa-language-select-indicator {
  background-position: -120px -60px;
}

/* line 530, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-PR .aa-language-select-indicator {
  background-position: -90px -60px;
}

/* line 534, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-RU .aa-language-select-indicator {
  background-position: -60px -60px;
}

/* line 538, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-ES .aa-language-select-indicator {
  background-position: -150px -30px;
}

/* line 542, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-CH .aa-language-select-indicator {
  background-position: -60px -30px;
}

/* line 546, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-GB .aa-language-select-indicator {
  background-position: -180px 0px;
}

/* line 550, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-UY .aa-language-select-indicator {
  background-position: -120px 0px;
}

/* line 554, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-VE .aa-language-select-indicator {
  background-position: -90px 0px;
}

/* line 558, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-TT .aa-language-select-indicator {
  background-position: -30px -30px;
}

/* line 562, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-BM .aa-language-select-indicator {
  background-position: -120px -180px;
}

/* line 566, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-AW .aa-language-select-indicator {
  background-position: -210px -90px;
}

/* line 570, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-CW .aa-language-select-indicator {
  background-position: -60px -150px;
}

/* line 574, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-KY .aa-language-select-indicator {
  background-position: 0px -180px;
}

/* line 578, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-BB .aa-language-select-indicator {
  background-position: -210px 0px;
}

/* line 582, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-JM .aa-language-select-indicator {
  background-position: -60px -90px;
}

/* line 586, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-AG .aa-language-select-indicator {
  background-position: -210px -150px;
}

/* line 590, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-TC .aa-language-select-indicator {
  background-position: 0px -30px;
}

/* line 594, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-HT .aa-language-select-indicator {
  background-position: 0px -120px;
}

/* line 598, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-AI .aa-language-select-indicator {
  background-position: -210px -180px;
}

/* line 602, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-BS .aa-language-select-indicator {
  background-position: -210px -30px;
}

/* line 606, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-BQ .aa-language-select-indicator {
  background-position: -30px 0px;
}

/* line 610, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-LC .aa-language-select-indicator {
  background-position: -30px -60px;
}

/* line 614, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-GD .aa-language-select-indicator {
  background-position: -60px -120px;
}

/* line 618, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-KN .aa-language-select-indicator {
  background-position: -120px -30px;
}

/* line 622, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-VC .aa-language-select-indicator {
  background-position: -90px -30px;
}

/* line 626, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-SX .aa-language-select-indicator {
  background-position: 0px -60px;
}

/* line 630, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-VG .aa-language-select-indicator {
  background-position: -60px 0px;
}

/* line 634, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-DM .aa-language-select-indicator {
  background-position: -30px -150px;
}

/* line 638, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-PY .aa-language-select-indicator {
  background-position: 0px -210px;
}

/* line 642, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-DK .aa-language-select-indicator {
  background-position: -30px -210px;
}

/* line 646, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-NO .aa-language-select-indicator {
  background-position: -60px -210px;
}

/* line 650, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-SE .aa-language-select-indicator {
  background-position: -90px -210px;
}

/* line 654, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-IL .aa-language-select-indicator {
  background-position: -120px -210px;
}

/* line 658, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-HK .aa-language-select-indicator {
  background-position: -150px -210px;
}

/* line 662, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-NZ .aa-language-select-indicator {
  background-position: -180px -210px;
}

/* line 666, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-FI .aa-language-select-indicator {
  background-position: -210px -210px;
}

/* line 670, ../../../../../../src/main/sass/content/css/partials/_icons.scss */
.aa-cntry-HU .aa-language-select-indicator {
  background-position: 0 -240px;

}
