// Import colors and constants from sec-styles
@import 'sec/sec-styles/constants.scss';

/* LESS file for custom AA bootstrap theme */

/* Bootstrap overrides */
/* ************************************************************* */

$modal-backdrop-bg: rgba(0,0,0,0.5);

$aa-mw: 1600px;
$tooltip-max-width: $aa-mw;
$popover-max-width: $aa-mw;

/* COLOR AND FONT VARIABLES */
/* ************************************************************* */
// AA specific colors
$aa-font:             "AmericanSans",Arial,sans-serif;
$aa-font-light:       "AmericanSansLight",Arial,sans-serif;
$aa-font-medium:      "AmericanSansMedium",Arial,sans-serif;
$aa-highlight-blue:   #E1F0FA;
$aa-teal:             #B1E1EB;
$aa-light-blue:		  #4db4fa;
$aa-blue:			  #0078d2;
$aa-med-blue:         #0061ab;
$aa-dark-blue:        #00467f;

$aa-white:            #FDFDFF;
$aa-ultra-light-gray: #F5F7F7;
$aa-bootstrap-gray:   #dddddd;
$aa-light-gray:		  #D0DAE0;
$aa-gray:			  #9DA6AB;
$aa-med-gray:		  #627A88;
$aa-dark-gray:		  #36495A;
$aa-black:            #131313;


$aa-light-red:		  #FF6360;
$aa-red:              #F52305;
$aa-med-red:          #FF3336;
$aa-dark-red:		  #C30019;
$aa-dark-pink:        #F50057;
$aa-pink:             #E040FB;

$aa-light-yellow:     #FFF176;
$aa-yellow:           #FFEB3B;
$aa-golden-yellow:    #FFCA28;
$aa-light-orange:	  #FAAF00;
$aa-med-orange:       #FF9800;
$aa-orange:		      #D14904;
$aa-yellow-green:	  #D1D532;
$aa-light-green:      #00A248;
$aa-green:			  #008712;
$aa-dark-green:       #004e1e;

$aa-aqua:             #80FFFF;
$aa-med-teal:         #009999;
$aa-dark-teal:        #006566;

$atc-pink:            #ff8080;
$atc-aqua:            #80ffff;
$atc-brown:           #724d27;
$atc-red:             #ff0000;
$atc-light-brown:     #cc7a00;
$atc-teal-green:      #009999;
$atc-dark-green:      #006566;
$atc-yellow:          #ffee28;

// $icon-font-path:          "";

/* PAGE SIZE VARIABLES */
/* ************************************************************* */
$container-full: 100%;


/* NAVIGATION BAR VARIABLES */
/* ************************************************************* */
// Navbar base color variables - WHITE
$navbar-aa-bg: $aa-white;
$navbar-aa-border: transparent;
$navbar-aa-brand-color: $aa-med-gray;
$navbar-aa-brand-hover-color: $aa-dark-gray;
$navbar-aa-brand-hover-bg: transparent;
// Navbar text/link variables - WHITE
$navbar-aa-color: $aa-dark-gray;
$navbar-aa-link-color: $aa-blue;
$navbar-aa-link-hover-color: $aa-light-gray;
$navbar-aa-link-hover-bg: transparent;
$navbar-aa-link-active-color: $aa-dark-blue;
$navbar-aa-link-active-bg: transparent;
$navbar-aa-link-disabled-color: $aa-ultra-light-gray;
$navbar-aa-link-disabled-bg: transparent;
// Navbar toggle/collapse - WHITE
$navbar-aa-toggle-border-color: $aa-blue;
$navbar-aa-toggle-hover-bg: $aa-white;
$navbar-aa-toggle-icon-bar-bg: $aa-blue;
$navbar-aa-border: $aa-white;
// Responsive navbar
$responsive-aa-header-height: 50px;
$sidenav-width: 225px;

$aa-font-UX1: Helvetica,Arial,sans-serif;