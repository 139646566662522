@import 'variables-aa.scss';

.h1, h1 {
  font-family: $aa-font;
  font-size: 38px;
  line-height: 44px;
  color: $aa-blue;
}

.h2, h2 {
  font-family: $aa-font;
  font-size: 30px;
  line-height: 36px;
  color: $aa-dark-blue;
}

.h3, h3 {
  font-family: $aa-font;
  font-size: 24px;
  line-height: 30px;
  color: $aa-med-gray;
}

.h4, h4 {
  font-family: $aa-font;
  font-size: 18px;
  line-height: 18px;
  color: $aa-med-gray;
}

.h5, h5 {
  font-family: $aa-font;
  font-size: 14px;
  line-height: 18px;
  color: $aa-dark-gray;
}

.h6, h6 {
  font-family: $aa-font;
  font-size: 11px;
  line-height: 16px;
  color: $aa-med-gray;
}

.disclaimer {
  font-size: 12px;
  line-height: 18px;
  color: $aa-med-gray;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.section .section+.section {
    border-top: 1px solid #d0dae0;
    margin-top: 30px;
    padding-top: 30px;
    background: 0
}

p+.section {
    margin-top: 20px
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: $spacer;
  margin-bottom: ($spacer / 2);
}

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: ($spacer / 2);
  margin-bottom: ($spacer / 2);
}
