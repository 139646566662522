// Color constants for the entire application

$aa-white       : #FFFFFF;
$aa-black       : #131313;

// Blue
$aa-blue-10     : #E1F0FA;
$aa-blue-20     : #B8E2FF;
$aa-blue-30     : #4DB4FA;
$aa-blue-32     : #4DB4FA50;
$aa-blue-35     : #4DB4FA80;
$aa-blue-40     : #078DF2;
$aa-blue-50     : #0078D2;
$aa-blue-70     : #005DA3;
$aa-blue-90     : #00467F;
$aa-blue-3050   : #4db4fa80;
    
//Red
$aa-red-10      : #FEEBE9;
$aa-red-20      : #FDD5D0;
$aa-red-50      : #F52305;
$aa-red-70      : #C30019;

// Orange
$aa-orange-10   : #FFEFE5;
$aa-orange-20   : #FFCAA9;
$aa-orange-50   : #FF7318;
$aa-orange-70   : #C94F03;
$aa-orange-90   : #893602;

// Green
$aa-green-10    : #E2F7F1;
$aa-green-20    : #BAF1E3;
$aa-green-50    : #00B989;
$aa-green-70    : #008763;

// Gray
$aa-gray-10     : #F8F8F8;
$aa-gray-20     : #E7E8EA;
$aa-gray-30     : #D6D8D9;
$aa-gray-40     : #BBC1C5;
$aa-gray-50     : #9DA6AB;
$aa-gray-65     : #E7E8EA65;
$aa-gray-29     : #78788029;
$aa-gray-36     : #7878805c;


// Light Orange
$aa-light-orange-10     : #FFF5DC;
$aa-light-orange-20     : #FCD06B;
$aa-light-orange-50     : #FAAF00;
$aa-light-orange-70     : #D59500;

// Yellow Green
$aa-yellow-green-10     : #F8F9E3;
$aa-yellow-green-20     : #EFF294;
$aa-yellow-green-50     : #D1D535;
$aa-yellow-green-70     : #A6A820;

// Blue Gray
$aa-blue-gray-20        : #EBEEF0;
$aa-blue-gray-30        : #D0DAE0;
$aa-blue-gray-50        : #9BB3C0;
$aa-blue-gray-52        : #5271BE;
$aa-blue-gray-70        : #6E8999;
$aa-blue-gray-90        : #36495A;

// Alt
$aa-alt-teal-10         : #D1ECF1;
$aa-alt-teal-50         : #B1E1EB;
$aa-alt-red-orange-10   : #FFF3E0;
$aa-alt-red-orange-50   : #BF360C;
$aa-alt-green-10        : #DFF0D8;
$aa-alt-green-50        : #1B5E20;

// Hazmat
$aa-hazmat-red          : #FF0000;
$aa-hazmat-yellow       : #F8E71C;